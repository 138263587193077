import axios from 'axios';
import { showMessageError } from '../utils/handleError';

// var requestUrl = 'http://192.168.1.105:8085';
// var requestUrl = 'http://192.168.10.115:8084';
// var requestUrl = 'https://test.level-job.com';
// var requestUrl = 'https://mall.huitao588.top';
var requestUrl = 'https://xianzhi.level-job.com';

export default class Api {

  static getInstance() {

    if (!Api.instance) {

      Api.instance = new Api();
    }
    return Api.instance;
  }
  // 登录
  doGetVeryCode(params) {
    return this.callback('/admin/login/doGetVeryCode.action', params);
  }
  doAccountLogin(params) {
    return this.callback('/admin/login/accountLogin.action', params);
  }
  doTokenLogin(params) {
    return this.callback('/admin/login/tokenLogin.action', params);
  }
  // 提现模块
  doTakeCashList(params) {
    return this.callback('/admin/takeCash/queryTakeCash.action', params);
  }
  doPassTakeCash(params) {
    return this.callback('/admin/takeCash/passTakeCash.action', params);
  }
  doRefuseTakeCash(params) {
    return this.callback('/admin/takeCash/refuseTakeCash.action', params);
  }
  //用户管理
  doCustomerList(params) {
    return this.callback('/admin/customer/doCustomerList.action', params);
  }
  modifyCustomer(params) {
    return this.callback('/admin/customer/modifyCustomer.action', params);
  }
  doModifyCustomer(params) {
    return this.callback('/admin/customer/doModifyCustomer.action', params);
  }
  charge(params) {
    return this.callback('/admin/customer/charge.action', params);
  }
  doCharge(params) {
    return this.callback('/admin/customer/doCharge.action', params);
  }
  doAccountDetailList(params) {
    return this.callback('/admin/accountDetail/doAccountDetailList.action', params);
  }
  doTradeList(params) {
    return this.callback('/admin/trade/doTradeList.action', params);
  }
  doLogOut(params) {
    return this.callback('/login/doLogOut.action', params);
  }
  queryDictionary(params) {
    return this.callback('/api/dictionary/queryDictionary.action', params);
  }
  // 模块
  moduleList(params) {
    return this.callback('/admin/module/moduleList.action', params);
  }
  moduleInfo(params) {
    return this.callback('/admin/module/publishModule.action', params);
  }
  doPublishModule(params) {
    return this.callback('/admin/module/doPublishModule.action', params);
  }
  doModifyModule(params) {
    return this.callback('/admin/module/doModifyModule.action', params);
  }
  doDeleteModuleList(params) {
    return this.callback('/admin/module/doDeleteModuleList.action', params);
  }
  // 模块详情
  moduleDetailList(params) {
    return this.callback('/admin/moduleDetail/moduleDetailList.action', params);
  }
  moduleDetailInfo(params) {
    return this.callback('/admin/moduleDetail/publishModuleDetail.action', params);
  }
  doPublishModuleDetail(params) {
    return this.callback('/admin/moduleDetail/doPublishModuleDetail.action', params);
  }
  doModifyModuleDetail(params) {
    return this.callback('/admin/moduleDetail/doModifyModuleDetail.action', params);
  }
  doDeleteModuleDetailList(params) {
    return this.callback('/admin/moduleDetail/doDeleteModuleDetailList.action', params);
  }
  doModifyLevel(params) {
    return this.callback('/admin/moduleDetail/doModifyLevel.action', params);
  }
  /* 积分列表 */
  doCreditLogList(params) {
    return this.callback('/admin/creditLog/creditLogList.action', params);
  }
  doFeedbackList(params) {
    return this.callback('/admin/feedback/doFeedbackList.action', params);
  }
  doSiteList(params) {
    return this.callback('/admin/site/doSiteList.action', params);
  }
  modifySite(params) {
    return this.callback('/admin/site/modifySite.action', params);
  }
  doPublishSite(params) {
    return this.callback('/admin/site/doPublishSite.action', params);
  }
  doModifySite(params) {
    return this.callback('/admin/site/doModifySite.action', params);
  }
  doDeleteSiteList(params) {
    return this.callback('/admin/site/doDeleteSiteList.action', params);
  }
  doSiteDetailList(params) {
    return this.callback('/admin/siteDetail/doSiteDetailList.action', params);
  }
  doUserSiteDetailList(params) {
    return this.callback('/api/page/doSiteDetailList.action', params);
  }
  modifySiteDetail(params) {
    return this.callback('/admin/siteDetail/modifySiteDetail.action', params);
  }
  doPublishSiteDetail(params) {
    return this.callback('/admin/siteDetail/doPublishSiteDetail.action', params);
  }
  doModifySiteDetail(params) {
    return this.callback('/admin/siteDetail/doModifySiteDetail.action', params);
  }
  doDeleteSiteDetailList(params) {
    return this.callback('/admin/siteDetail/doDeleteSiteDetailList.action', params);
  }
  /** 模板消息 */
  moduleMessageList(params) {
    return this.callback('/admin/moduleMessage/moduleMessageList.action', params);
  }
  /** 短信消息 */
  messageList(params) {
    return this.callback('/admin/message/messageList.action', params);
  }
  /** 图文资讯 */
  ceefaxList(params) {
    return this.callback('/admin/ceefax/ceefaxList.action', params);
  }
  ceefaxInfo(params) {
    return this.callback('/admin/ceefax/publishCeefax.action', params);
  }
  doPublishCeefax(params) {
    return this.callback('/admin/ceefax/doPublishCeefax.action', params);
  }
  doModifyCeefax(params) {
    return this.callback('/admin/ceefax/doModifyCeefax.action', params);
  }
  doDeleteCeefaxList(params) {
    return this.callback('/admin/ceefax/doDeleteCeefaxList.action', params);
  }
  doSendCeefaxToAll(params) {
    return this.callback('/admin/ceefax/doSendCeefaxToAll.action', params);
  }
  doSendCeefaxToUser(params) {
    return this.callback('/admin/ceefax/doSendCeefaxToUser.action', params);
  }
  /** 平台参数设置 */
  doSettingInfo(params) {
    return this.callback('/admin/setting/doSettingInfo.action', params);
  }
  doModifySetting(params) {
    return this.callback('/admin/setting/doModifySetting.action', params);
  }
  doRandomQrList(params) {
    return this.callback('/admin/randomQrGroup/doRandomQrList.action', params);
  }
  doPublishRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doPublishRandomQr.action', params);
  }
  doModifyRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQr.action', params);
  }
  doDeleteRandomQr(params) {
    return this.callback('/admin/randomQrGroup/doDeleteRandomQr.action', params);
  }
  doModifyRandomQrForIds(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQrForIds.action', params);
  }
  getmQrGroupGzhTicket(params) {
    return this.callback('/admin/randomQrGroup/getGzhTicket.action', params);
  }
  doWechatQrList(params) {
    return this.callback('/admin/wechatQr/doWechatQrList.action', params);
  }
  doWechatQrForImportList(params) {
    return this.callback('/admin/wechatQr/doWechatQrForImportList.action', params);
  }
  doPublishWechatQr(params) {
    return this.callback('/admin/wechatQr/doPublishWechatQr.action', params);
  }
  doModifyWechatQr(params) {
    return this.callback('/admin/wechatQr/doModifyWechatQr.action', params);
  }
  doDeleteWechatQr(params) {
    return this.callback('/admin/wechatQr/doDeleteWechatQr.action', params);
  }
  doModifyWechatQrInfo(params) {
    return this.callback('/admin/wechatQr/doModifyWechatQrInfo.action', params);
  }
  doModifyRandomQrInfo(params) {
    return this.callback('/admin/randomQrGroup/doModifyRandomQrInfo.action', params);
  }
  viewLogList(params) {
    return this.callback('/admin/viewLog/doViewLogList.action', params);
  }
  doReportRandomQrList(params) {
    return this.callback('/admin/reportQr/doReportRandomQrList.action', params);
  }
  doReportWechatQrList(params) {
    return this.callback('/admin/reportQr/doReportWechatQrList.action', params);
  }
  doModifyReportWechatQr(params) {
    return this.callback('/admin/reportQr/doModifyReportWechatQr.action', params);
  }
  // 管理员
  adminList(params) {
    return this.callback('/admin/admin/adminList.action', params);
  }
  adminSelector(params) {
    return this.callback('/admin/admin/adminSelector.action', params);
  }
  adminInfo(params) {
    return this.callback('/admin/admin/publishAdmin.action', params);
  }
  doPublishAdmin(params) {
    return this.callback('/admin/admin/doPublishAdmin.action', params);
  }
  doModifyAdmin(params) {
    return this.callback('/admin/admin/doModifyAdmin.action', params);
  }
  doDeleteAdminList(params) {
    return this.callback('/admin/admin/doDeleteAdminList.action', params);
  }
  doReportXianzhiList(params) {
    return this.callback('/admin/reportXianzhi/doReportXianzhiList.action', params);
  }
  doXianzhiItemList(params) {
    return this.callback('/admin/xianzhiItem/doXianzhiItemList.action', params);
  }
  doModifyXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/doModifyXianzhiItem.action', params);
  }
  doDeleteXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/doDeleteXianzhiItem.action', params);
  }
  auditXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/auditXianzhiItem.action', params);
  }
  modifyXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/modifyXianzhiItem.action', params);
  }
  /* 数据字典 */
  dictionaryListAdmin(params) {
      return this.callback('/admin/dictionary/dictionaryList.action', params);
  }
  doSyncDictionary(params) {
      return this.callback('/admin/dictionary/doSyncDictionary.action', params);
  }
  /* 聊天消息 */
  chatMsgList(params) {
      return this.callback('/admin/chatMsg/chatMsgList.action', params);
  }

  // 淘宝账号设置
  weixinClientList(params) {
    return this.callback('/admin/weixinClient/weixinClientList.action', params);
  }
  publishWeixinClient(params) {
    return this.callback('/admin/weixinClient/publishWeixinClient.action', params);
  }
  doPublishWeixinClient(params) {
    return this.callback('/admin/weixinClient/doPublishWeixinClient.action', params);
  }
  doModifyWeixinClient(params) {
    return this.callback('/admin/weixinClient/doModifyWeixinClient.action', params);
  }
  doDeleteWeixinClientList(params) {
    return this.callback('/admin/weixinClient/doDeleteWeixinClientList.action', params);
  }
  doAreaSettingList(params) {
    return this.callback('/admin/areaSetting/doAreaSettingList.action', params);
  }
  doPublishAreaSetting(params) {
    return this.callback('/admin/areaSetting/doPublishAreaSetting.action', params);
  }
  modifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/modifyAreaSetting.action', params);
  }
  doModifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/doModifyAreaSetting.action', params);
  }
  doDeleteAreaSetting(params) {
    return this.callback('/admin/areaSetting/doDeleteAreaSetting.action', params);
  }
  
  // 订单模块
  doOrderList(params) {
    return this.callback('/admin/order/queryOrder.action', params);
  }
  doOrderInfo(params) {
    return this.callback('/admin/order/doOrderInfo.action', params);
  }
  // 查询物流信息
  doExpressInfo(params) {
    return this.callback('/admin/order/doExpressInfo.action', params);
  }
  doOrderSuccess(params) {
    return this.callback('/admin/order/orderSuccess.action', params);
  }
  queryPostCompany(params) {
    return this.callback('/admin/postCompany/queryPostCompany.action', params);
  }
  doOrderExpress(params) {
    return this.callback('/admin/order/orderExpress.action', params);
  }
  doModifyExpress(params) {
    return this.callback('/admin/order/doModifyExpress.action', params);
  }
  doCancelOrder(params) {
    return this.callback('/admin/order/cancelOrder.action', params);
  }
  doRefundOrder(params) {
    return this.callback('/admin/order/refundOrder.action', params);
  }
  acceptRefundOrder(params) {
    return this.callback('/admin/order/acceptRefundOrder.action', params);
  }
  refuseRefundOrder(params) {
    return this.callback('/admin/order/refuseRefundOrder.action', params);
  }
  doModifyPayAmount(params) {
    return this.callback('/admin/order/doModifyPayAmount.action', params);
  }
  doModPayAmount(params) {
    return this.callback('/admin/order/doModPayAmount.action', params);
  }
  modifyOrder(params) {
    return this.callback('/admin/order/modifyOrder.action', params);
  }
  doModifyOrder(params) {
    return this.callback('/admin/order/doModifyOrder.action', params);
  }
  /* 操作日志 */
  logList(params) {
      return this.callback('/admin/log/logList.action', params);
  }
  queryLogInfo(params) {
      return this.callback('/admin/log/queryLogsInfo.action', params);
  }
  recoveryList(params) {
    return this.callback('/admin/recovery/recoveryList.action', params);
  }
  publishRecovery(params) {
    return this.callback('/admin/recovery/publishRecovery.action', params);
  }
  doPublishRecovery(params) {
    return this.callback('/admin/recovery/doPublishRecovery.action', params);
  }
  doModifyRecovery(params) {
    return this.callback('/admin/recovery/doModifyRecovery.action', params);
  }
  doDeleteRecoveryList(params) {
    return this.callback('/admin/recovery/doDeleteRecoveryList.action', params);
  }
  recoveryDetailList(params) {
    return this.callback('/admin/recoveryDetail/recoveryDetailList.action', params);
  }
  publishRecoveryDetail(params) {
    return this.callback('/admin/recoveryDetail/publishRecoveryDetail.action', params);
  }
  doPublishRecoveryDetail(params) {
    return this.callback('/admin/recoveryDetail/doPublishRecoveryDetail.action', params);
  }
  doModifyRecoveryDetail(params) {
    return this.callback('/admin/recoveryDetail/doModifyRecoveryDetail.action', params);
  }
  doDeleteRecoveryDetailList(params) {
    return this.callback('/admin/recoveryDetail/doDeleteRecoveryDetailList.action', params);
  }
  recoveryOrderList(params) {
    return this.callback('/admin/recoveryOrder/recoveryOrderList.action', params);
  }
  doCancelRecoveryOrder(params) {
    return this.callback('/admin/recoveryOrder/doCancelRecoveryOrder.action', params);
  }
  doFinishRecoveryOrder(params) {
    return this.callback('/admin/recoveryOrder/doFinishRecoveryOrder.action', params);
  }
  doRecoveryOrderInfo(params) {
    return this.callback('/admin/recoveryOrder/doRecoveryOrderInfo.action', params);
  }
  modifyRecoveryOrderInfo(params) {
    return this.callback('/admin/recoveryOrder/modifyRecoveryOrderInfo.action', params);
  }
  doModifyRecoveryOrder(params) {
    return this.callback('/admin/recoveryOrder/doModifyRecoveryOrder.action', params);
  }
  recoveryPosterList(params) {
    return this.callback('/admin/recoveryPoster/recoveryPosterList.action', params);
  }
  doPublishRecoveryPoster(params) {
    return this.callback('/admin/recoveryPoster/doPublishRecoveryPoster.action', params);
  }
  modifyRecoveryPoster(params) {
    return this.callback('/admin/recoveryPoster/modifyRecoveryPoster.action', params);
  }
  doModifyRecoveryPoster(params) {
    return this.callback('/admin/recoveryPoster/doModifyRecoveryPoster.action', params);
  }
  doDeleteRecoveryPosterList(params) {
    return this.callback('/admin/recoveryPoster/doDeleteRecoveryPosterList.action', params);
  }
  doDistributeRecoveryOrder(params) {
    return this.callback('/admin/recoveryOrder/doDistributeRecoveryOrder.action', params);
  }
  doChangeDistributeRecoveryOrder(params) {
    return this.callback('/admin/recoveryOrder/doChangeDistributeRecoveryOrder.action', params);
  }
  recoveryPosterOrderList(params) {
    return this.callback('/admin/recoveryPosterOrder/recoveryPosterOrderList.action', params);
  }
  bookOrderList(params) {
    return this.callback('/admin/bookOrder/bookOrderList.action', params);
  }
  doBookOrderInfo(params) {
    return this.callback('/admin/bookOrder/doBookOrderInfo.action', params);
  }
  doModifyRecoveryOrderAdminRemark(params) {
    return this.callback('/admin/recoveryOrder/doModifyRecoveryOrderAdminRemark.action', params);
  }
  getGzhTicket(params){
    return this.callback('/admin/xianzhiItem/getGzhTicket.action', params);
  }
  doDisplayRecoveryPoster(params){
    return this.callback('/admin/recoveryPoster/doDisplayRecoveryPoster.action', params);
  }
  doCloseRecoveryPoster(params){
    return this.callback('/admin/recoveryPoster/doCloseRecoveryPoster.action', params);
  }
  posterApplyList(params){
    return this.callback('/admin/posterApply/posterApplyList.action', params);
  }
  queryPosterApplyList(params){
    return this.callback('/admin/posterApply/queryPosterApplyList.action', params);
  }
  publishRecoveryOrderList(params){
    return this.callback('/admin/posterApply/publishRecoveryOrderList.action', params);
  }
  publishAllRecoveryOrderList(params){
    return this.callback('/admin/posterApply/publishAllRecoveryOrderList.action', params);
  }
  deletePosterApplyList(params){
    return this.callback('/admin/posterApply/deletePosterApplyList.action', params);
  }
  deletePushList(params){
    return this.callback('/admin/posterApply/deletePushList.action', params);
  }
  deletePosterApply(params){
    return this.callback('/admin/posterApply/deletePosterApply.action', params);
  }
  deletePush(params){
    return this.callback('/admin/posterApply/deletePush.action', params);
  }
  doApplyRecoveryOrder(params){
    return this.callback('/admin/recoveryOrder/doApplyRecoveryOrder.action', params);
  }
  doModifySiteDetailLevel(params){
    return this.callback('/admin/siteDetail/doModifySiteDetailLevel.action', params);
  }
  doModifyRecoveryDetailLevel(params){
    return this.callback('/admin/recoveryDetail/doModifyRecoveryDetailLevel.action', params);
  }
  doDistributeApply(params){
    return this.callback('/admin/recoveryOrder/doDistributeApply.action', params);
  }
  newRecoveryOrderList(params){
    return this.callback('/admin/recoveryOrder/newRecoveryOrderList.action', params);
  }
  doRefundRecoveryOrder(params){
    return this.callback('/admin/recoveryOrder/doRefundRecoveryOrder.action', params);
  }
  pushRecoveryPosterList(params){
    return this.callback('/admin/recoveryPoster/pushRecoveryPosterList.action', params);
  }
  doRecoveryPosterInfo(params){
    return this.callback('/admin/recoveryPoster/doRecoveryPosterInfo.action', params);
  }

  doRentItemList(params){
    return this.callback('/admin/rentItem/doRentItemList.action', params);
  }

  modifyRentItem(params){
    return this.callback('/admin/rentItem/modifyRentItem.action', params);
  }

  doModifyRentItem(params){
    return this.callback('/admin/rentItem/doModifyRentItem.action', params);
  }

  doDeleteRentItem(params){
    return this.callback('/admin/rentItem/doDeleteRentItem.action', params);
  }

  auditRentItem(params){
    return this.callback('/admin/rentItem/auditRentItem.action', params);
  }

  doModifyRentExtendStatus(params){
    return this.callback('/admin/rentItem/doModifyRentExtendStatus.action', params);
  }

  doRefundRentItem(params){
    return this.callback('/admin/rentItem/doRefundRentItem.action', params);
  }

  promotList(params){
    return this.callback('/admin/promot/promotList.action', params);
  }

  finishPromot(params){
    return this.callback('/admin/promot/finishPromot.action', params);
  }

  doRefundPromot(params){
    return this.callback('/admin/promot/doRefundPromot.action', params);
  }
  
  getUrl(){
    return requestUrl;
  }

  callback(url, data) {
    return new Promise((resolve, reject) => {
      if (url.indexOf("thirdparty") != -1) {
        url = "http://192.168.1.118:8082" + url;
      } else {
        if (url.substr(0, 4) !== 'http') {
          url = requestUrl + url;
        }
      }
      axios({
        url: url,
        data: data,
        method: 'POST',
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        /* if (res.data) {
          resolve(res.data);
        } */
        // 如果出现请登录，跳转登录页面
        if (res.data && !res.data.success && res.data.error && res.data.error === "请登录") {
          showMessageError("请重新登录");
          setTimeout(() => {
            window.location= "/";
          }, 3000);
        }
        else {
          resolve(res.data);
        }
      }).catch((error) => {
        resolve({ success: false, error: "服务器连接失败" })
      })
    });
  }
}