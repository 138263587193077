import * as constants from './constants';
import chinaImg from '../../../assets/img/china.svg';
export default (state = constants.defaultState, { type, payload }) => {
	let newState = JSON.parse(JSON.stringify(state));
	newState.dictionaryList = state.dictionaryList;


	switch (type) {
		case constants.USERINFO: {
			newState.userInfo = payload;
			break;
		}
		case constants.LANGUAGE: {
			newState.language = payload;
			break;
		}
		case constants.PERMISSION: {
			break;
		}
		case constants.PERMISSION_SUCCESS: {
			newState.menuList = payload;
			break;
		}
		case constants.MERCHANT: {
			newState.merchant = payload;
			break;
		}
		case constants.SHOP: {
			newState.shop = payload;
			break;
		}
		case constants.EXPRESSER: {
			newState.expresser = payload;
			break;
		}
		case constants.SCHOOL: {
			newState.school = payload;
			break;
		}
		case constants.CLEAR: {
			newState = {
				language: localStorage.getItem('language') || 'zh-CN',
				languages: [
					{
						key: 'zh-CN',
						title: '中文',
						flag: chinaImg,
					},
				],
				menuList: [],
				existRoute: {}, // 用户路由列表
				isNeedPermission: false, /** 是否需要菜单-路由权限控制，默认需要true; 若设置为false，所有的permKey可以去掉 */
				userInfo: {
					clientType: 4,
					customerType: 3
				},
				clientType: 4,
				menus: [],
			};
			break;
		}
		case 'persist/REHYDRATE': {
			if (payload) {
				var incoming = payload.login; // Carts is the name of the reducer
				if (incoming) {
					newState = { ...newState, ...incoming };
				}
			}
			break;
		}
		case constants.RESET_STATE: {
			newState = {
				...state,
				...payload
			};
			break;
		}
		case constants.SET_MERCHANT_LIST: {
			newState.merchantInfoList = payload;
			break;
		}
		default:
			return newState;
	}
	return newState;
}